import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import Container from "../components/Container";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import SectionHeader from "../components/SectionHeader";
import Row from "../components/Row";
import Column from "../components/Column";
import Card from "../components/Card";
import Image from "../components/Image";
import Table from "../components/Table";
import TableRow from "../components/TableRow";
import TableCell from "../components/TableCell";
import CareersBanner from "../components/CareersBanner";
import { max_md, max_lg } from "../utils/mediaQuery";

const history = [
  ["2011.7", "グラフィカルTwitter分析「whotwi」リリース"],
  ["2016.6", "Twitterアカウント管理ツール「Cheetah」をリリース"],
  ["2016.7", "株式会社AutoScale 設立"],
  ["2017.1", "代々木オフィス開設"],
  ["2017.6", "Cheetahのサービス名を「SocialDog」に変更"],
  ["2018.3", "SocialDog登録アカウント数が5万を突破"],
  ["2018.9", "AutoScaleがAIアクセラレーター第6期に採択される"],
  ["2018.12", "オフィスを西新宿に移転"],
  ["2019.2", "SocialDog登録アカウント数が10万を突破"],
  ["2019.3", "SocialDog iPhone アプリをリリース"],
  ["2019.7", "SocialDog Android アプリをリリース"],
  ["2020.10", "西新宿オフィスを閉鎖、フルリモート勤務体制へ移行"],
  ["2020.12", "SocialDog登録アカウント数が50万を突破"],
  ["2021.9", "社名を「株式会社SocialDog」に変更"],
  ["2022.10", "SocialDog登録アカウント数が100万を突破"],
  ["2022.11", "四谷オフィス開設"],
  ["2023.4", "whotwiのサービスを終了"],
  ["2023.6", "Twitter社とTwitter Enterprise APIの利用契約を締結"],
  ["2023.11", "SocialDogがInstagramとFacebookに対応"],
];

export default () => (
  <>
    <SEO description="株式会社SocialDogの企業概要。" title="企業情報" />
    <PageHeader title="Company Profile" lead="企業情報" />
    <Container>
      <Section>
        <Row>
          <Column col={4}>
            <SectionHeader title="代表メッセージ" />
          </Column>
          <Column>
            <Lead>あらゆる人がSNSを活用できる世界を創る</Lead>
            <MessageParagraph>
              SNSの登場により、予算や知名度がなくても、より多くの人に想いを届けられるようになりました。
            </MessageParagraph>
            <MessageParagraph>
              私達はSNS上でのなめらかなコミュニケーションを実現し、あらゆる人の想い伝えたい人に伝えることで、あらゆる人が主人公になれる世界を創ります。
            </MessageParagraph>
            <MessageParagraph>
              ただ便利なだけでなく、UI/UXを追求し、誰でも簡単に使えて、最高に使いやすく、ワクワクするようなサービスを目指します。
            </MessageParagraph>
            <MessageParagraph>
              ゲーム、ウェブサービス、ECサイト、飲食店、サロン、ブロガー、YouTuberなど、SNSを活用されるすべての皆さまを、私達はテクノロジーの力で応援します。
            </MessageParagraph>
            <ProfileCardBase>
              <Card>
                <Row alignItems="center" justifyContent="center" gutter="8px">
                  <Column col={2} colSp={12}>
                    <ProfileImage
                      filename="ceo.jpg"
                      alt=""
                      width="80"
                      height="80"
                    />
                  </Column>
                  <Column col={10} colSp={12}>
                    <ProfileName>KONISHI Masafumi</ProfileName>
                    <ProfilePosition>Founder / CEO</ProfilePosition>
                  </Column>
                </Row>
                <ProfileContent>
                  2011年早稲田大学在学中にTwitter（現X）分析サービス「whotwi」をリリースし3ヶ月で月間100万PVの人気サービスになる。2012年早稲田大学人間科学部卒業。
                  2012年、株式会社エウレカにエンジニアとして入社し、国内最大級の婚活マッチングサービス「Pairs」の立ち上げから開発、インフラ、グロースハック、サービス企画などに携わり、2016年退職。2016年7月、株式会社AutoScale（現SocialDog）を創業。
                </ProfileContent>
                <ProfileSns>
                  <ProfileSnsLink
                    href="https://twitter.com/koni"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    X（Twitter）
                  </ProfileSnsLink>
                  <ProfileSnsLink
                    href="https://www.facebook.com/konimasa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </ProfileSnsLink>
                  <ProfileSnsLink
                    href="https://koni.hateblo.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ブログ
                  </ProfileSnsLink>
                </ProfileSns>
              </Card>
            </ProfileCardBase>
          </Column>
        </Row>
      </Section>
    </Container>
    <Container>
      <Section>
        <Row>
          <Column col={4}>
            <SectionHeader title="沿革" />
          </Column>
          <Column>
            <Table>
              {history.map(row => (
                <TableRow key={row[0]}>
                  <TableCell heading>{row[0]}</TableCell>
                  <TableCell>{row[1]}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Column>
        </Row>
      </Section>
    </Container>
    <Container>
      <Section>
        <Row>
          <Column col={4}>
            <SectionHeader title="会社情報" />
          </Column>
          <Column>
            <Table>
              <TableRow>
                <TableCell heading>会社名</TableCell>
                <TableCell>株式会社SocialDog</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>所在地</TableCell>
                <TableCell>
                  <ul>
                    <li>
                      登記上の本店：〒135-0063 東京都江東区有明3-7-26
                      有明フロンティアビルB棟9階
                    </li>
                    <li>
                      四谷オフィス：〒160-0003 東京都新宿区四谷本塩町2-8 A
                      YOTSUYA
                    </li>
                    <li>
                      メールセンター（郵便物送付先）：〒135-0045
                      東京都江東区古石場2-11-7 1295号室
                    </li>
                  </ul>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>代表取締役</TableCell>
                <TableCell>小西 将史</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>資本金</TableCell>
                <TableCell>4,477万円 (資本準備金含む)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>設立</TableCell>
                <TableCell>2016年7月25日</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>決算期</TableCell>
                <TableCell>3月</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>従業員数</TableCell>
                <TableCell>34名 (アルバイト・業務委託含む)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>株主</TableCell>
                <TableCell>
                  当社役員 &nbsp;/&nbsp;
                  <a
                    href="https://east.vc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    East Ventures
                  </a>
                  &nbsp;/&nbsp;
                  <a
                    href="https://reality.vc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    REALITY ACCELERATOR
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>事業内容</TableCell>
                <TableCell>
                  インターネットサービス事業
                  <br />
                  SNS運用担当者のためのマーケティングツール「
                  <a
                    href="https://social-dog.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SocialDog
                  </a>
                  」の開発、運営
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>認証</TableCell>
                <TableCell>
                  情報セキュリティマネジメントシステム(ISMS) ISO/IEC 27001:2022,
                  JIS Q 27001:2023
                  <br />
                  (認証範囲 :
                  SNSの運用に関するインターネットサービスの開発・運用・保守)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>適格請求書発行事業者登録番号</TableCell>
                <TableCell>T8010401126286</TableCell>
              </TableRow>
              <TableRow>
                <TableCell heading>お問い合わせ先</TableCell>
                <TableCell>
                  <a href="https://web.social-dog.net/contact">
                    SocialDogサービスに関するお問い合わせ
                  </a>
                  <br />
                  <br />
                  <Link to="/contact/">上記以外に関するお問い合わせ</Link>
                </TableCell>
              </TableRow>
            </Table>
          </Column>
        </Row>
      </Section>
    </Container>
    <CareersBanner />
  </>
);

const Section = styled.section`
  margin: 0 0 80px;
`;

const Lead = styled.h3`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 24px;

  ${max_md(css`
    font-size: 24px;
  `)}
`;

const MessageParagraph = styled.p`
  margin: 0 0 1rem;

  &:last-child {
    margin: 0;
  }
`;

const ProfileCardBase = styled.div`
  margin: 24px 0 0;
`;

const ProfileImage = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 9999px;

  ${max_lg(css`
    width: 64px;
    margin: 0 auto 10px;
  `)}
`;

const ProfileName = styled.p`
  color: ${({ theme }) => theme.colors.main};
  font-weight: bold;
  margin: 0;

  ${max_lg(css`
    text-align: center;
  `)}
`;

const ProfilePosition = styled.p`
  color: ${({ theme }) => theme.colors.muted};
  font-size: 12px;

  ${max_lg(css`
    text-align: center;
  `)}
`;

const ProfileContent = styled.p`
  margin: 0;
  padding: 24px 0 0;
`;

const ProfileSns = styled.div`
  display: flex;
  margin: 16px -4px 0;

  ${max_lg(css`
    margin-top: 10px;
    justify-content: center;
  `)}
`;

const ProfileSnsLink = styled.a`
  color: ${({ theme }) => theme.colors.normal};
  background-color: ${({ theme }) => theme.colors.baseSecondary};
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  border-radius: 6px;
  margin: 4px;
`;
